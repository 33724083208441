import React from 'react'
import Destination from '../component/Destination'
import DestinationData from '../component/DestinationData'
import kv7 from '../asets/K7.jpg'
import kv2 from '../asets/k2.jpg'
import kv3 from '../asets/k3.jpg'
import kv8 from '../asets/k8.jpg'
import kv5 from '../asets/k5.jpg'
import kv6 from '../asets/k6.jpg'
import Navbar from '../component/Navbar'
import Footer from '../component/Footer'
const DearKavya = () => {
  return (
    <div className='destination'>
      
      <h1 >Hey Dear Welcome to my Heart</h1>
      <DestinationData
    className="first-des"
      heading="The prettiest girl of my life"
      text=" नजरे उनसे मिली दिल जवा हो गया ,
          लवो पे जो भी थी सब बया गया||
          अब तो मुझे रातों में नीद नही आती,
           अचानक ये मेरे दिल को क्या हो गया।। "
      image1={kv2}
      image2={kv3}/>
       <DestinationData
    className="first-des-reverse"
      heading="Tum mile to lamhe ye hansi lagne lge"
      text=" चांदी सोना एक तरफ,
      तेरा होना एक तरफ ।।
      एक तरफ तेरी आंखे ,
      जादू टोना एक तरफ😍 ।। "
      image1={kv6}
      image2={kv5}/>
       <DestinationData
    className="first-des"
      heading="Oii Coffee pine chle kya curly hair?"
      text="जलती कैंडल, दो कप कॉफ़ी और अँधेरी रात,
      आप और हम करें अपने दिल की बात. "
      image1={kv7}
      image2={kv8}/>
      <Footer/>
    </div>
  )
}

export default DearKavya
